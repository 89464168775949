body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	right: 18px;
	top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
	background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
	background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	height: 24px;
	width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
	background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
	position: fixed;
	height: 100%;
}

/* General sidebar styles */
.bm-menu {
	background: #ffffff;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: black;
}

/* Individual item */
.bm-item {
	display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
	background: rgba(0, 0, 0, 0.3);
}
